import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import emailjs from 'emailjs-com';
import './ModalForm.css';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS

Modal.setAppElement('#root');

const ModalForm = ({ onComplete }) => {
  const [isOpen, setIsOpen] = useState(() => {
    const isModalFilled = localStorage.getItem('isModalFilled');
    return !isModalFilled; // If the modal has been filled, it should not be open
  });
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true); // Show the loader



    // EmailJS send function
    // emailjs
    //   .send(
    //     'service_sz5iard',
    //     'template_yr80diw',
    //     formData,
    //     'uivkp6nRfdAdrk7b6'
    //   )

     emailjs
      .send(
        'service_nzko262',
        'template_f9pb2hy',
        formData,
        't0V6nrP3otExLmGcD'
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          localStorage.setItem('isModalFilled', true); // Set flag in localStorage
          setIsOpen(false); // Close the modal upon successful submission
          setIsLoading(false); // Hide the loader
          if (typeof onComplete === 'function') {
            onComplete(); // Callback to inform parent component that modal is complete
          }
        },
        (error) => {
          console.log('FAILED...', error);
          setIsLoading(false); // Hide the loader on failure
        }
      );
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
    if (isOpen) {
      // Prevent scrolling on the background content when modal is open
      document.body.style.overflow = 'hidden';
    } else {
      // Restore scrolling when modal is closed
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      shouldCloseOnOverlayClick={false} // Prevent closing on overlay click
      className="bg-white rounded-lg shadow-lg p-6 mt-[50px] mx-auto w-[350px] sm:w-auto sm:absolute sm:top-4 sm:right-4 sm:left-4 sm:max-w-md sm:mt-[70px]"
      overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center"
    >
      <div
        className="bg-white rounded-lg shadow-lg p-6 w-full sm:max-w-md"
        data-aos="fade-up-left"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="2000"
      >
        <h1 className="border-l-[4px] border-l-[#E7F9F9] pl-[10px] text-[#064343]">
          Start your journey into the world of possibilities with the power of
          technology.
        </h1>
        <h1 className="border-l-[4px] border-l-green-600 pl-[10px] text-[#064343]">
          We are here to help you live that dream.
        </h1>
        <h2 className="text-xl font-[500] text-[#009C9C] pt-[10px] mb-4">
          Fill out the form here, let's make it happen!
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-[#009C9C]">
              Name
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-[#009C9C]">
              Phone Number
            </label>
            <input
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-[#009C9C]">
              Email
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="flex justify-end">
            {isLoading ? (
              <div className="loader"></div> // Replace with your loader component or spinner
            ) : (
              <button
                type="submit"
                className="bg-indigo-600 text-white px-4 py-2 rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Submit
              </button>
            )}
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ModalForm;