import * as yup from "yup"


export const basicSchema = yup.object().shape({
    firstName:yup.string().required("Firstname Required"),
    surName:yup.string().required("Surname Required"),
    email: yup.string().email("Please enter a valid email").required("Email Required"),
    phoneNumber:yup.number().required("Phone Number Required"),
message:yup.string().min(6).required("Message can't be empty")
})
