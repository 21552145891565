import React from "react";
import Navbar from "../Components/Head/Navbar";
import { Box, Grid, Typography } from "@mui/material";
import innovation from "../assets/innovation.JPG";
import Footer from "../Components/Footer/Footer";
import Mansonry from "../Components/Sections/Mansonry";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
        <Helmet>
                <meta charSet="utf-8" />
                <title> About | Nupat Technologies  </title>
                <link rel="canonical" href="https://nupat.africa/" />
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-2HLS2GJ4LF" />
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-2HLS2GJ4LF');
          `}
        </script>
            </Helmet>
      <Navbar />
      <Box
        sx={{
          height: "320px",
          background:
            "linear-gradient(269.76deg, #131E47 0.21%, #00D8FF 167.56%)",
        }}
      >
        <Box align="center" sx={{ pt: { lg: 16, md: 16, xs: 13 } }}>
          <Typography
            sx={{
              fontFamily: "outfit",
              color: "#fff",
              fontWeight: 600,
              fontSize: { lg: "40px", md: "40px", xs: "26px" },
              mx: { xs: 3 },
            }}
          >
            Encouraging Innovation and Promoting Collaboration{" "}
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontFamily: "outfit",
              color: "#fff",
              fontWeight: 200,
              width: { lg: "50%", md: "50%", xs: "90%" },
            }}
          >
            We are raising world class tech professionals, building problem
            solving innovations and helping you bring your dreams to realities{" "}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box sx={{ margin: "auto", width: "85%", mt: 5, mb: 7 }}>
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "outfit",
              fontWeight: 300,
              fontSize: "20px",
            }}
          >
            Our focus is on fostering growth and driving innovation.
          </Typography>
          <Grid container spacing={3} sx={{ mt: 3 }}>
            <Grid item lg={6}>
              <Box>
                <Typography
                  sx={{
                    fontFamily: "outfit",
                    fontWeight: 200,
                    fontSize: "16px",
                  }}
                >
                  NUPAT Technologies is a software development company from
                  African descent birthed by the vision of becoming a leading
                  brand in world class innovation and delivery of masterpiece
                  and cutting edge technology. The Company is founded by a group
                  of distinct, highly skilled, innovative and experienced tech
                  experts.
                  <br />
                  <br />
                  The Company is registered with the Corporate Affairs
                  Commission of the Federal Republic of Nigeria, established
                  under the Companies and Allied Matters Act 2020. Nupat is the
                  partner of choice for many of the world’s leading enterprises,
                  SMEs and technology companies. We help businesses elevate
                  their value through custom software development, product
                  design, QA and consultancy services. We can help to maintain
                  and modernize your IT infrastructure and solve various
                  infrastructure-specific issues a business may face.
                  <br />
                  <br />
                  NUPAT TECHNOLOGIES also provides intensive practical software
                  development boot-camp that is ushering in a new generation of
                  indigenous software developers driving innovation and software
                  development in the information and technological industry. The
                  training utilizes the experience and skill sets of veterans in
                  the industry. Quality practical training and online resources
                  are used in a conducive, team building and challenging
                  environment to inspire and mold the interns to become great
                  tech experts.
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6}>
              <Box>
                <img src={innovation} width="100%" alt="innovation" />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ bgcolor: "#F8FAFB" }}>
        <Box sx={{ margin: "0 auto", width: "80%", py: 7 }}>
          <Grid container spacing={4}>
            <Grid item lg={4} md={6}>
              <Box
                sx={{
                  bgcolor: "#E8F9FF",
                  boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.09)",
                  p: 3,
                  height: "200px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "outfit",
                    fontWeight: 600,
                    fontSize: "18px",
                  }}
                >
                  Our Vision
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "outfit",
                    fontSize: "15px",
                    fontWeight: 200,
                    textAlign: "center",
                    mt: 1,
                    fontStyle: "italic",
                  }}
                >
                  Our vision is to become a leading brand in the worldclass
                  innovation and delivery of masterpiece and cutting-edge
                  technology
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={4} md={6}>
              <Box
                sx={{
                  bgcolor: "#E1EFFF",
                  boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.09)",
                  p: 3,
                  height: "200px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "outfit",
                    fontWeight: 600,
                    fontSize: "18px",
                  }}
                >
                  Our Mission
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "outfit",
                    fontSize: "15px",
                    fontWeight: 200,
                    textAlign: "center",
                    mt: 1,
                    fontStyle: "italic",
                  }}
                >
                  Our vision is to become a leading brand in the worldclass
                  innovation and delivery of masterpiece and cutting-edge
                  technology
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={4}>
              <Box
                sx={{
                  bgcolor: "#E0E7FF",
                  boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.09)",
                  p: 3,
                  height: "200px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "outfit",
                    fontWeight: 600,
                    fontSize: "18px",
                  }}
                >
                  Our Strategy
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "outfit",
                    fontSize: "15px",
                    fontWeight: 200,
                    textAlign: "center",
                    mt: 1,
                    fontStyle: "italic",
                  }}
                >
                  We are building a great team of talents and experts innovation
                  driven with plans centered on maximum clients satisfaction
                </Typography>
              </Box>
            </Grid>
          </Grid>


        </Box>
        <Box
          sx={{
            margin: "0 auto",
            width: { lg: "80%", md: "80%", xs: "85%" },
            py: 7,
          }}
        >
          <Box align="center" sx={{ mt: 13 }}>
            <Typography
              sx={{
                fontFamily: "outfit",
                fontWeight: 600,
                fontSize: "20px",
                mb: 1,
              }}
            >
              Our Goals
            </Typography>
            <Typography
              sx={{
                fontFamily: "outfit",
                fontWeight: 200,
                fontSize: "16px",
                color: "#54595F",
                mb: 0.7,
              }}
            >
              Establishment of a leading tech hub / innovation center in Africa
            </Typography>
            <Typography
              sx={{
                fontFamily: "outfit",
                fontWeight: 200,
                fontSize: "16px",
                color: "#54595F",
                mb: 0.7,
              }}
            >
              Raising the largest community of developers in Africa
            </Typography>
            <Typography
              sx={{
                fontFamily: "outfit",
                fontWeight: 200,
                fontSize: "16px",
                color: "#54595F",
                mb: 0.7,
              }}
            >
              Become Africa’s leading provider of Software Services and
              Applications through innovative drive
            </Typography>
            <Typography
              sx={{
                fontFamily: "outfit",
                fontWeight: 200,
                fontSize: "16px",
                color: "#54595F",
                mb: 0.7,
              }}
            >
              Become a major player in Fin-Tech Solutions in Africa
            </Typography>
          </Box>
        </Box>

        <Typography
          sx={{
            mt: 4,
            fontFamily: "Outfit",
            fontSize: { lg: "60px", md: "60px", xs: "30px" },
            fontWeight: 600,
            textDecoration: "underline",
            textAlign: "center",
          }}
        >
          The Happy Family
        </Typography>
        <Box
          sx={{
            margin: "0 auto",
            width: { lg: "80%", md: "80%", xs: "100%" },
            mt: 1,
            overflow: "scroll",
          }}
        >
          <Mansonry />
        </Box>
     
      </Box>
      <Footer />
    </>
  );
};

export default About;
